import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import MainHeader from './MainHeader';
import Loader from "../commons/Loader";
import {
  baseStyles,
  BORDER_RADIUS,
  BOTTOM_BAR_HEIGHT,
  BOTTOM_BAR_HEIGHT_XS,
  changeBodyColor,
  DRAWER_WIDTH,
  IS_BOTTOM_TAB_PRESENT,
  TOOLBAR_HEIGHT,
  TOOLBAR_HEIGHT_XS,
  WhiteColorButton,
  withMediaQueries,
  withMultipleStyles
} from "src/styles/Styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Avatar, Button, List, Typography} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {get} from "lodash";
import Space from "../commons/Space";
import {
  ColorBackground,
  ColorBackgroundXS,
  ColorSubTitle,
  ColorTheme,
  ColorThemeLite,
  ColorTitle,
  ColorWhite
} from "src/styles/Colors";
import {FormattedMessage} from "react-intl";
import LinkHelper from "../../utils/LinkHelper";
import DialogView from "../commons/DialogView";
import TextView from "../commons/TextView";
import AppBar from "@material-ui/core/AppBar";
import {FontWeightBold, FontWeightRegular} from "src/res/FontWeight";
import PathHelper, {BOTTOM_MENUS} from "../../utils/PathHelper";
import ImageBackground from "../commons/ImageBackground";
import {ImageIcon} from "../commons/ViewHelper";
import {
  getRedirectSearch,
  PathActivityReviews,
  PathCampaigns,
  PathCampaignsLetter,
  PathGames,
  PathHome,
  PathJoin,
  PathLogout,
  PathMyReels,
  PathPlay,
  PathPlays,
  PathProfile,
  PathReward,
  PathScreens,
  PathShow
} from "src/js/components/routes/NavHelper";
import auth from "src/js/utils/auth";

const useStyles = () => ({
  listItemMenu: {
    borderRadius: BORDER_RADIUS,
    margin: 8,
    marginTop: 10,
    marginBottom: 10,
    minHeight: 44,
    width: DRAWER_WIDTH - 40,
    "&:hover": {
      backgroundColor: ColorThemeLite,
    },
    "&.Mui-focused": {
      backgroundColor: ColorTheme,
    },
    "&.Mui-selected": {
      backgroundColor: ColorTheme,
    },
    "&.Mui-selected:hover": {
      backgroundColor: ColorTheme,
    },
  },
  bottomBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: ColorWhite
  },
  appHome: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

class AdminHome extends React.Component {

  constructor(props) {
    super(props);
    this.readOnly = !auth.isAuthenticated();
    this.state = {
      openDrawer: false
    }
    this.menus = [
      {nameId: this.readOnly ? 'menu.discover' : 'menu.campaigns', pathname: PathCampaigns, morePathnames: [PathCampaignsLetter], image: require('src/images/ic_menu_campaigns.png'), imageS: require('src/images/ic_menu_campaigns_s.png')},
      // {nameId: 'menu.myRewards', pathname: '/rewards', image: require('src/images/ic_menu_rewards.png'), imageS: require('src/images/ic_menu_rewards_s.png')},
    ];
    if (!this.readOnly) {
      if (this.props.user.bId === 1 || this.props.user.bId === 2 || this.props.user.bId === 3) {
        this.menus.push({nameId: 'menu.showcases', pathname: PathScreens, image: require('src/images/ic_menu_digital_display.png'), imageS: require('src/images/ic_menu_digital_display_s.png')});
      }
      this.menus.push({nameId: 'menu.play', pathname: PathPlays, morePathnames: [PathPlay, PathShow], image: require('src/images/ic_menu_play.png'), imageS: require('src/images/ic_menu_play_s.png')});
      this.menus.push({nameId: 'menu.rewardsWon', pathname: '/wons', morePathnames: [PathReward], image: require('src/images/ic_menu_rewards_won.png'), imageS: require('src/images/ic_menu_rewards_won_s.png')});
      if (this.props.user.bId === 1 || this.props.user.bId === 3) {
        this.menus.push({nameId: 'menu.games', pathname: '/games', morePathnames: [PathGames], image: require('src/images/ic_menu_game.png'), imageS: require('src/images/ic_menu_game_s.png')});
      }
    }
    // this.menus.push({nameId: 'menu.redeemOfferSteps', pathname: '/helpRedeemOffer', image: require('src/images/ic_menu_redeem_offer.png'), imageS: require('src/images/ic_menu_redeem_offer_s.png')});
    if (!this.readOnly) {
      this.menus.push({nameId: 'menu.settings', pathname: '/settings', morePathnames: [PathProfile], image: require('src/images/ic_menu_settings.png'), imageS: require('src/images/ic_menu_settings_s.png')});
    } else {
      // this.menus.push({nameId: 'menu.aboutUs', pathname: PathAboutUsPublic, morePathnames: null, image: require('src/images/ic_menu_about.png'), imageS: require('src/images/ic_menu_about_white_s.png')});
    }
    // this.menus1 = [
    //   {nameId: 'menu.campaigns', pathname: '/campaigns', image: require('src/images/ic_menu_campaigns.png'), imageS: require('src/images/ic_menu_campaigns_s.png')},
    //   // {nameId: 'menu.marketingCampaigns', pathname: '/marketingCampaigns'},
    //   // {nameId: 'menu.loyaltyCampaigns', pathname: '/loyaltyCampaigns'},
    //   // {nameId: 'menu.educationCampaigns', pathname: '/educationCampaigns'},
    //   // {nameId: 'menu.trainingCampaigns', pathname: '/trainingCampaigns'},
    //   // {nameId: 'menu.socialCampaigns', pathname: '/socialCampaigns'},
    //   // {nameId: 'menu.influencerCampaigns', pathname: '/influencerCampaigns'},
    //   {nameId: 'menu.myRewards', pathname: '/rewards', image: require('src/images/ic_menu_rewards.png'), imageS: require('src/images/ic_menu_rewards_s.png')},
    //   {nameId: 'menu.redeemOfferSteps', pathname: '/helpRedeemOffer', image: require('src/images/ic_menu_redeem_offer.png'), imageS: require('src/images/ic_menu_redeem_offer_s.png')},
    // ];
    // this.menus2 = [
    //   {nameId: 'menu.settings', pathname: '/profile', image: require('src/images/ic_menu_settings.png'), imageS: require('src/images/ic_menu_settings_s.png')},
    // ];
  }

  componentDidMount() {
    setTimeout(() => {
      changeBodyColor(this.props.isXS ? ColorBackgroundXS : ColorBackground);
    }, 100);
  }

  render() {
    const {isLoading, isBlockedLoading, user, app, classes, isDrawerHidden, isXS} = this.props;
    const {openLogoutDialog} = this.state;

    const hideDrawer = this.shouldHideDrawer();
    const isTopHeaderPresent = this.isTopHeaderPresent();
    const drawerWidth = hideDrawer ? 0 : DRAWER_WIDTH;
    const contentTopMargin = isTopHeaderPresent ? (isXS ? TOOLBAR_HEIGHT_XS : TOOLBAR_HEIGHT) : 0;
    const contentBottomMargin = this.isBottomTabPresent() ? isXS ? BOTTOM_BAR_HEIGHT_XS : BOTTOM_BAR_HEIGHT : 0;
    const contentRightMargin = app.isLTR ? 0 : drawerWidth;
    const contentLeftMargin = app.isLTR ? drawerWidth : 0;

    return (
      <div style={{backgroundColor: isXS ? ColorBackgroundXS : ColorBackground}}>
        {isTopHeaderPresent ?
          <MainHeader history={this.props.history} user={user} openDrawer={() => this.toggleDrawer(true)}/>
          : null
        }
        {!hideDrawer ? this.getDrawerWrapperView() : null}

        <div style={{display: 'flex', flex: 1, flexDirection: 'column', paddingLeft: contentLeftMargin}}>
          <div className={classes.flexColumn}>
            <div style={{position: 'absolute', left: contentLeftMargin, right: contentRightMargin, top: contentTopMargin, minHeight: window.appHeight - contentTopMargin - contentRightMargin, display: 'flex', flexDirection: 'column'}}>
              {this.props.children}
              <Space horizontal={contentBottomMargin} />
            </div>
            {isLoading && (
              <div className={classes.viewLoading} style={{left: app.isLTR ? drawerWidth : 0, right: app.isLTR ? 0 : drawerWidth}}>
                <CircularProgress />
              </div>
            )}
          </div>
        </div>

        {IS_BOTTOM_TAB_PRESENT ? this.renderBottomTabs() : null}

        {isBlockedLoading && <Loader />}
        <DialogView
          classes={classes}
          show={openLogoutDialog}
          title={this.context.intl.formatMessage({id: 'profile.logoutQ'})}
          positiveText={this.context.intl.formatMessage({id: 'generic.logout'})}
          onPositiveClicked={this.onLogoutClicked}
          neutralText={this.context.intl.formatMessage({id: 'generic.no'})}
          onNeutralClicked={this.onToggleDialogLogoutDialog.bind(this, false)}
        />

      </div>
    );
  }

  shouldHideDrawer = () => {
    const {isDrawerHidden} = this.props;
    return isDrawerHidden ; // || this.readOnly;
  }

  renderBottomTabs() {
    const {classes, history} = this.props;
    if (!this.isBottomTabPresent()) {
      return null;
    }

    const currentPath = history.location.pathname;
    return (
      <AppBar position="fixed" className={classes.bottomBar}>
        <Toolbar>
          {BOTTOM_MENUS.map((menu) => {
            const isSelected = currentPath == menu.pathname || this.pathStartsWith(currentPath, menu.morePathnames);
            return (
              <div className={classes.flexColumn} style={{marginTop: menu.moveToTop ? -24 : 0}}>
                <Button onClick={this.onDrawerMenuClicked.bind(this, menu, true)} style={{paddingTop: menu.moveToTop ? 0 : 4}}>
                  <div className={classes.viewColumn} style={{alignItems: 'center'}}>
                    <img src={isSelected ? menu.imageS : menu.image} width={menu.moveToTop ? 48 : 24} height={'auto'} />
                    <TextView textAlign={'center'} variant={'caption'} color={isSelected && menu.moveToTop ? ColorTheme : ColorSubTitle} fontWeight={isSelected ? FontWeightBold : FontWeightRegular} textId={menu.nameId} />
                  </div>
                </Button>
              </div>
            )
          })}
        </Toolbar>
      </AppBar>
    );
  }

  pathStartsWith = (path, paths) => {
    if (paths) {
      for (let i = 0; i < paths.length; i++) {
        const pathname = paths[i];
        if (path.startsWith(pathname)) {
          return true;
        }
      }
    }
    return false;
  }

  getDrawerWrapperView() {
    const {app, classes, history, user, isDrawerHidden} = this.props;
    const hideDrawer = this.shouldHideDrawer();
    const path = get(history, 'location.pathname');
    const isUserGuest = !user.registerType;

    const getListItems = (menus) => {
      return (
        menus.map((menu, index) => {
          let isSelected = false;
          if (menu.pathname === '/') {
            isSelected = path === menu.pathname || this.pathStartsWith(path, menu.morePathnames);
          } else {
            isSelected = path.startsWith(menu.pathname) || this.pathStartsWith(path, menu.morePathnames);
          }
          return(
            <ListItem className={classes.listItemMenu} button selected={isSelected} key={index +'-'+ menu.name} onClick={this.onDrawerMenuClicked.bind(this, menu)}>
              <img src={isSelected ? menu.imageS : menu.image} width={24} height={24} style={{objectFit: 'contain'}} />
              <Space vertical={12} />
              <Typography variant={'subtitle1'} className={isSelected ? classes.typoMedium : classes.typoNormal} style={{paddingTop: 4, paddingBottom: 4, color: isSelected ? ColorWhite : ColorSubTitle}}>
                <FormattedMessage id={menu.nameId} />
              </Typography>
            </ListItem>
          )
        })
      )
    };

    const drawerView = (
      <div className={classes.flexColumn} style={{width: DRAWER_WIDTH, backgroundColor: ColorWhite}}>
        <Toolbar style={{width: DRAWER_WIDTH, minHeight: TOOLBAR_HEIGHT, paddingLeft: 20, paddingRight: 20}}>
          {hideDrawer ?
            <div className={classes.flexRow} >
              <Space start={app.isLTR ? -8 : 0} end={app.isLTR ? 0 : -8} />
              <IconButton style={{color: ColorWhite}} onClick={() => {this.toggleDrawer(false)}}>
                {app.isLTR ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
              <Space vertical={8} />
              <Button style={{color: ColorWhite}} size={"large"} onClick={this.onHomeClicked}>
                <div className={classes.flexRow}>
                  <Avatar variant="square" src={require('src/images/logo.png')} />
                  <Space vertical={10} />
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <TextView textId={'app.name1'} color={ColorWhite} fontWeight={'bold'} />
                  </div>
                </div>
              </Button>
            </div>
            : <div className={classes.appHome}>
                <img src={require('src/images/logo_white.png')} style={{width: 42, height: 42, borderRadius: BORDER_RADIUS, backgroundColor: ColorTheme, padding: 8}} />
                <Space vertical={8} />
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <TextView variant={'h5'} textId={'app.name1'} color={ColorTitle} fontWeight={'bold'} />
                </div>
              </div>
          }
        </Toolbar>
        <Space horizontal={10} />
        <List disablePadding>
          {getListItems(this.menus)}
          {/*{getListItems(this.menus1)}*/}
          {/*{!hideDrawer ?*/}
          {/*  getListItems(this.menus2)*/}
          {/*  : null*/}
          {/*}*/}
        </List>
        <div className={classes.flexGrow} style={{height: '100%'}} />

        {!this.readOnly && !isUserGuest ?
          <div className={classes.viewColumnFullWidth} style={{position: 'relative', padding: 20}}>
            <ImageBackground source={require('src/images/img_user_bg.png')} style={{top: 0, left: 0, right: 0, bottom: 0, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
              <div className={classes.viewColumnFullWidth} style={{position: 'relative', justifyContent: 'center', alignItems: 'center', padding: 12}}>
                <div style={{width: 60, height: 60, padding: 1, borderRadius: 8, backgroundColor: ColorWhite, overflow: "hidden"}}>
                  <img src={user.imageUrl} width={'100%'} height={'100%'} style={{borderRadius: 8}} />
                </div>
                <Space horizontal={4} />
                <TextView numberOfLines={2} variant={'subtitle2'} style={{paddingTop: 4, paddingBottom: 4, color: ColorWhite}}>
                  {user.name}
                </TextView>
                <Space horizontal={8} />
                <WhiteColorButton
                  onClick={isUserGuest ? this.onJoinUsClicked : this.onToggleDialogLogoutDialog}
                  disableElevation={true}
                  variant={'contained'}
                  startIcon={<ImageIcon src={app.isLTR ? isUserGuest ? require('src/images/ic_login.png') : require('src/images/ic_logout.png') : isUserGuest ? require('src/images/ic_login_rtl.png') : require('src/images/ic_logout_rtl.png')} />}>
                  <FormattedMessage id={isUserGuest ? 'login.joinRab7Short' : 'generic.logout'} />
                </WhiteColorButton>
              </div>
            </ImageBackground>
          </div>
          : <Button
            disableElevation
            color={'primary'}
            variant={'contained'}
            onClick={this.onJoinUsClicked}
            style={{marginBottom: 20}}>
            <FormattedMessage id='login.joinRab7Short' />
          </Button>
        }
        {/*<Divider style={{width: '100%'}}/>*/}
        {/*<Divider style={{width: '100%'}}/>*/}
        {/*<div className={classes.flexRow} style={{padding: 8}}>*/}
        {/*  <LanguageSelector />*/}
        {/*</div>*/}
        {/*<Divider light style={{width: '100%'}}/>*/}
        {/*<ListItem key={'keyTnc'} button onClick={this.onTncClicked}>*/}
        {/*  <Typography variant={'caption'}>*/}
        {/*    <FormattedMessage id='login.tnc' />*/}
        {/*  </Typography>*/}
        {/*</ListItem>*/}
      </div>
    );
    return (
      <div>
        {hideDrawer ?
          <Drawer
            variant="temporary"
            anchor={'left'}
            open={this.state.openDrawer}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
              onBackdropClick: () => {this.toggleDrawer(false)}
            }}>
            {drawerView}
          </Drawer>
          : <Drawer
            variant="permanent"
            open>
            {drawerView}
          </Drawer>
        }
      </div>
    );
  }

  isTopHeaderPresent() {
    const currentPath = this.props.history.location.pathname;
    if (currentPath.startsWith(PathPlay +'/')
      || currentPath.startsWith(PathShow +'/')
      || currentPath.startsWith(PathMyReels)
      || currentPath.startsWith(PathActivityReviews +'/')) {
      return false;
    }
    return true;
  }

  isBottomTabPresent() {
    const {isDrawerHidden, history} = this.props;
    const hideDrawer = this.shouldHideDrawer();
    return IS_BOTTOM_TAB_PRESENT && PathHelper.isBottomTabPresent(hideDrawer, history);
  }

  onHomeClicked = () => {
    this.props.history.replace('/');
  };

  onTncClicked = () => {
    LinkHelper.openTnc(this.props.app.defaultLocale);
  };

  onToggleDialogLogoutDialog = (show) => {
    this.setState({openLogoutDialog: show});
  };

  onJoinUsClicked = () => {
    this.props.history.push({
      pathname: PathJoin,
      search: getRedirectSearch(this.props.history.location),
    });
  }

  onLogoutClicked = () => {
    this.setState({openLogoutDialog: false});
    this.props.history.push(PathLogout);
  };

  onDrawerMenuClicked = (menu, shouldReplace = false) => {
    shouldReplace = menu.pathname === PathHome;
    const {history} = this.props;
    this.toggleDrawer(false);
    const path = history.location.pathname + history.location.hash;
    let pathToMove = null;
    if (menu.pathname !== path) {
      pathToMove = menu.pathname;
    } else {
      pathToMove = menu.pathname +'#0';
    }
    if (shouldReplace) {
      history.replace(pathToMove);
    } else {
      history.push(pathToMove);
    }
  };

  toggleDrawer(open) {
    this.setState({
      openDrawer: open
    });
  }
}

AdminHome.propTypes = {
  classes: PropTypes.object,
  app: PropTypes.object,
  isLoading: PropTypes.bool,
  isBlockedLoading: PropTypes.bool,
  history: PropTypes.object,
  user: PropTypes.object,
  children: PropTypes.element
};

AdminHome.contextTypes = {
  intl:PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    app: state.app,
    isLoading: state.isLoading,
    isBlockedLoading: state.isBlockedLoading,
    user: state.admin.user,
  };
}

const mapDispatchToProps = (/*dispatch*/) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withMultipleStyles(baseStyles, useStyles)(withMediaQueries([
  ['isDrawerHidden', theme => theme.breakpoints.down('sm'), {defaultMatches: true}],
  ['isXS', theme => theme.breakpoints.down('xs'), {defaultMatches: true}],
])(AdminHome)));
