import {cloneDeep, concat, isEmpty, isPlainObject, map} from 'lodash';

import initialState from './initialState';
import * as types from '../constants/actionTypes';
import constants from '../constants';
import {FormattedMessage} from "react-intl";
import React from "react";
import {calculateTotalPaginatedDataSize} from "src/js/utils/ApiHelper";
import auth from "src/js/utils/auth";

export default function showcaseReducer(state = cloneDeep(initialState.admin), action) {
  switch (action.type) {

    case types.LOG_FUN_ACTIVITY_SHOWCASE:
    case types.LOG_FUN_ACTIVITY_SHOWCASE_SUCCESS:
    case types.LOG_FUN_ACTIVITY_SHOWCASE_FAILURE: {
      if (action.type === types.LOG_FUN_ACTIVITY_SHOWCASE_SUCCESS && action.data.screen) {
        const screen = cloneDeep(action.data.screen);
        auth.setUser(screen);
        state.user = screen;
      }
      return state;
    }

    case types.RESET_SCREENS_ERROR: {
      state.screens.errMsg = null;
      state.screens.successMsg = null;
      return state;
    }

    case types.FETCH_CACHE_SCREENS: {
      if (state.cacheListData && state.cacheListData.data && state.cacheListData.type === action.type) {
        state.screens.paginated = state.cacheListData.data;
        state.screens.scrollTo = state.cacheListData.scrollPosition;
        state.screens.isFetchInProgress = false;
        state.screens.cache = true;
      } else {
        state.screens.cacheMiss = true;
      }
      return state;
    }
    case types.FETCH_SCREENS: {
      delete state.screens.cache;
      delete state.screens.cacheMiss;
      state.screens.isFetchInProgress = true;
      state.screens.errMsg = '';
      state.screens.errMsgF = '';
      return state;
    }
    case types.FETCH_SCREENS_SUCCESS: {
      const data = isPlainObject(action.data) && action.data;
      if (!isEmpty(data)) {
        if (data.page === 1) {
          state.screens.paginated = data;
        } else {
          state.screens.paginated.data = concat(state.screens.paginated.data, data.data);
        }
        state.screens.paginated.page = data.page;
        state.screens.paginated.perPage = data.perPage;
        state.screens.paginated.canLoadMore = data.canLoadMore;
        state.screens.paginated.totalSize = calculateTotalPaginatedDataSize(data);
      }
      state.screens.isFetchInProgress = false;
      return state;
    }
    case types.FETCH_SCREENS_FAILURE: {
      if (action.data && action.data.error) {
        state.screens.errMsgF = action.data.error;
      } else {
        state.screens.errMsgF = 'generic.server_error_msg';
      }
      state.screens.isFetchInProgress = false;
      return state;
    }

    case types.FETCH_SCREEN: {
      state.screens.item.errMsg = null;
      state.screens.item.errCode = null;
      return state;
    }
    case types.FETCH_SCREEN_SUCCESS: {
      state.screens.item.data = action.data;
      return state;
    }
    case types.FETCH_SCREEN_FAILURE: {
      if (action.data && action.data.error) {
        state.screens.item.errMsg = action.data.error;
        state.screens.item.errCode = action.data.errorCode;
      } else {
        state.screens.item.errMsg = 'generic.server_error_msg';
      }
      return state;
    }

    case types.RESET_MANAGE_SCREEN_ERROR_MESSAGE:
    case types.MANAGE_SCREEN: {
      state.screens.item.data.errMsg = null;
      state.screens.item.data.errCode = null;
      return state;
    }

    case types.MANAGE_SCREEN_SUCCESS: {
      state.screens.item.data = action.data;
      return state;
    }

    case types.MANAGE_SCREEN_FAILURE: {
      if (action.data && action.data.error) {
        state.screens.item.data.errMsg = action.data.error;
        state.screens.item.data.errCode = action.data.errorCode;
      } else {
        state.screens.item.data.errMsg = 'generic.server_error_msg';
      }
      return state;
    }

    case types.TOGGLE_SCREEN_STATE_SUCCESS: {
      const data = map(state.screens.paginated.data, (activity) => {
        if (activity.id === action.id) {
          activity.state = action.newState;
        }
        return activity;
      });
      const successMessageId = action.newState === constants.activity.state.INACTIVE ? 'screens.screenDeactivated' : 'screens.screenActivated';
      state.screens.paginated.data = data;
      state.screens.successMsg = <FormattedMessage id={successMessageId}/>;
      state.screens.errMsg = null;
      return state;
    }

    case types.UPDATE_SCREEN_ORIENTATION_SUCCESS: {
      const data = map(state.screens.paginated.data, (activity) => {
        if (activity.id === action.id) {
          activity.orientation = action.orientation;
        }
        return activity;
      });
      const successMessageId = 'screens.orientationUpdated';
      state.screens.paginated.data = data;
      state.screens.successMsg = <FormattedMessage id={successMessageId}/>;
      state.screens.errMsg = null;
      return state;
    }

    case types.DELETE_SCREEN_SUCCESS: {
      const successMessageId = 'screens.screenDeleted';
      let index = -1;
      for (let i = 0; i < state.screens.paginated.data.length; i++) {
        let item = state.screens.paginated.data[i];
        if (item.id === action.id) {
          index = i;
          break;
        }
      }
      if (index > -1) {
        state.screens.paginated.data.splice(index, 1);
        state.screens.paginated.totalSize--;
      }
      state.screens.successMsg = <FormattedMessage id={successMessageId}/>;
      state.screens.errMsg = null;
      return state;
    }

    case types.DELETE_SCREEN_FAILURE:
    case types.TOGGLE_SCREEN_STATE_FAILURE:
    case types.UPDATE_SCREEN_ORIENTATION_FAILURE: {
      if (action.data && action.data.error) {
        state.screens.errMsg = action.data.error;
      } else {
        state.screens.errMsg = <FormattedMessage id={'generic.server_error_msg'}/>;
      }
      return state;
    }

    default:
      return state;
  }

}
