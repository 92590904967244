import {cloneDeep, isEmpty} from 'lodash';

import auth from '../utils/auth';
import initialState from './initialState';
import * as types from '../constants/actionTypes';
import {
  DO_LOGIN_SCREEN,
  FETCH_CACHE_ACTIVITIES,
  FETCH_CACHE_GAMES,
  FETCH_CACHE_SCREENS, FETCH_FUN_ACTIVITIES_SHOWCASE_SUCCESS, LOG_FUN_ACTIVITY_SHOWCASE_SUCCESS
} from '../constants/actionTypes';

import profileReducer, {formatProfileData} from './profileReducer';
import notificationReducer from './notificationReducer';
import activitiesReducer from './activitiesReducer';
import funReducer from "./funReducer";
import {logDebug} from "../utils/AppLog";
import gamesReducer from "src/js/reducers/gamesReducer";
import showcaseReducer from "src/js/reducers/showcaseReducer";

if (isEmpty(initialState.admin.user)) {
  initialState.admin.user = cloneDeep(auth.getUser());
}

export default function adminReducer(state = cloneDeep(initialState.admin), action) {
  let newState = cloneDeep(state);

  switch (action.type) {
    case types.CACHE_LIST_DATA: {
      newState.cacheListData = action.data;
      return newState;
    }

    case types.RESET_CACHE_LIST_DATA: {
      logDebug('RESET_CACHE_LIST_DATA');
      newState.cacheListData = {};
      return newState;
    }

    case types.FETCH_ACTIVITY_SUCCESS:
    case types.MANAGE_ACTIVITY_SUCCESS: {
      if (action.data) {
        if (newState.cacheListData && newState.cacheListData.data && newState.cacheListData.type === FETCH_CACHE_ACTIVITIES) {
          let isFound = false;
          newState.cacheListData.data.data.forEach((item) => {
            if (item.id === action.data.id) {
              isFound = true;
              item.title = action.data.title;
              item.startAt = action.data.startAt;
              item.endAt = action.data.endAt;
              item.imageUrl = action.data.imageUrl;
              item.rewardImageUrl = action.data.rewardImageUrl;
              item.questionId = action.data.questionId;
              item.ad = action.data.ad;
            }
          });
          if (!isFound && action.type === types.MANAGE_ACTIVITY_SUCCESS) {
            action.data.isNew = 1;
            newState.cacheListData.data.data.splice(0, 0, action.data);
          }
        }
      }
      return newState;
    }

    case types.FETCH_GAME_SUCCESS:
    case types.MANAGE_GAME_SUCCESS: {
      if (action.data && action.data.id) {
        if (newState.cacheListData && newState.cacheListData.data && newState.cacheListData.type === FETCH_CACHE_GAMES) {
          let isFound = false;
          newState.cacheListData.data.data.forEach((item) => {
            if (item.id === action.data.id) {
              isFound = true;
              item.name = action.data.name;
              item.createdAt = action.data.createdAt;
              item.updatedAt = action.data.updatedAt;
              item.imageUrl = action.data.imageUrl;
            }
          });
          if (!isFound && action.type === types.MANAGE_GAME_SUCCESS) {
            action.data.isNew = 1;
            newState.cacheListData.data.data.splice(0, 0, action.data);
          }
        }
      }
      return newState;
    }

    case types.MANAGE_SCREEN_SUCCESS: {
      if (action.data && action.data.id) {
        if (newState.cacheListData && newState.cacheListData.data && newState.cacheListData.type === FETCH_CACHE_SCREENS) {
          let isFound = false;
          newState.cacheListData.data.data.forEach((item) => {
            if (item.id === action.data.id) {
              isFound = true;
              item.type = action.data.type;
              item.name = action.data.name;
              item.orientation = action.data.orientation;
              item.latitude = action.data.latitude;
              item.longitude = action.data.longitude;
              item.countryId = action.data.countryId;
              item.localityId = action.data.localityId;
              item.subLocalityId = action.data.subLocalityId;
              item.createdAt = action.data.createdAt;
              item.updatedAt = action.data.updatedAt;
              item.lastAccessAt = action.data.lastAccessAt;
            }
          });
          if (!isFound && action.type === types.MANAGE_SCREEN_SUCCESS) {
            action.data.isNew = 1;
            newState.cacheListData.data.data.splice(0, 0, action.data);
          }
        }
      }
      return newState;
    }

    case types.DO_LOGOUT: {
      initialState.admin.user = {};
      newState = cloneDeep(initialState.admin);
      return newState;
    }

    case '@@router/LOCATION_CHANGE': {
      const user = newState.user;
      const notifications = newState.notifications;
      const activityNationalities = newState.activities.nationalities;
      const activityLocalities = newState.activities.localities;
      const activitySubLocalities = newState.activities.subLocalities;
      const cacheListData = newState.cacheListData;
      newState = cloneDeep(initialState.admin);
      newState.user = user || {};
      newState.notifications = notifications || cloneDeep(initialState.admin.notifications);
      newState.activities.nationalities = activityNationalities || cloneDeep(initialState.admin.activities.nationalities);
      newState.activities.localities = activityLocalities || cloneDeep(initialState.admin.activities.localities);
      newState.activities.subLocalities = activitySubLocalities || cloneDeep(initialState.admin.activities.subLocalities);
      newState.cacheListData = cacheListData;
      return newState;
    }

    case types.DO_LOGIN_SUCCESS:
    case types.DO_LOGIN_MFA_SUCCESS:
    case types.DO_LOGIN_PLAY_JOIN_SUCCESS2:
    case types.DO_LOGIN_SCREEN_SUCCESS: {
      if (!isEmpty(initialState.admin.user)) {
        initialState.admin.user = {};
      }

      newState = cloneDeep(initialState.admin);
      let data = (action.data && action.data.user) || {};
      if (!isEmpty(data)) {
        data = formatProfileData(data);
      }
      newState.user = cloneDeep(data);
      return newState;
    }

    default: {
      newState = profileReducer(newState, action);
      newState = notificationReducer(newState, action);
      // newState = branchReducer(newState, action);
      // newState = giftReducer(newState, action);
      // newState = earnPointFormulaReducer(newState, action);
      // newState = redeemPointFormulaReducer(newState, action);
      // newState = employeeReducer(newState, action);
      // newState = userReducer(newState, action);
      // newState = postsReducer(newState, action);
      newState = funReducer(newState, action);
      newState = activitiesReducer(newState, action);
      newState = gamesReducer(newState, action);
      newState = showcaseReducer(newState, action);
      // newState = employeeChatsReducer(newState, action);
      // newState = customerChatsReducer(newState, action);
      // newState = insightsReducer(newState, action);
      // newState = systemUserReducer(newState, action);
      // newState = transactionReducer(newState, action);
      // newState = userBalanceReducer(newState, action);
      // newState = supportChatsReducer(newState, action);
      // newState = transferTransactionReducer(newState, action);
      // newState = productCategoriesReducer(newState, action);
      // newState = productItemReducer(newState, action);
      return newState;
    }
  }

}
